import { render, staticRenderFns } from "./CellRendererFile.vue?vue&type=template&id=25cf2c63&scoped=true"
import script from "./CellRendererFile.vue?vue&type=script&lang=js"
export * from "./CellRendererFile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25cf2c63",
  null
  
)

export default component.exports